<template>
  <div>
    <br />
    <SelectOptionPeriodRent :rentId="rentId" :showPeriodAll="false" />
    <br />
    <Molded>
      <ProductAdd :rentId="rentId" :type="type" :statusPatrimony="statusPatrimony" />
    </Molded>
    <br />
    <b-row>
      <b-col sm="6">
        <ProductRent :title="titleProductRent" :type="type" />
      </b-col>
      <b-col sm="6">
        <ProductSelected :title="titleProductSelected" :type="type" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import ProductAdd from "./ProductAdd.vue";
import SelectOptionPeriodRent from "../../rental/period-rental/SelectOptionPeriodRent.vue";
import ProductRent from "./ProductRent.vue";
import ProductSelected from "./ProductSelected.vue";

export default {
  components: {
    SelectOptionPeriodRent,
    InputText,
    ProductRent,
    Molded,
    ProductSelected,
    ProductAdd,
    SelectOptionPeriodRent,
  },
  name: "RentAndSelected",
  props: {
    rentId: String,
    statusPatrimony: Number,
    titleProductRent: String,
    titleProductSelected: String,
    type: String,
  },
};
</script>
