<template>
  <div>
    <div class="div-title">
      <span class="title">
        <i class="fa-solid fa-boxes-stacked"></i>
        {{ title }}
      </span>
    </div>
    <Molded>
      <div class="div-action">
        <b-row>
          <b-col sm="6">
            <div class="input-container">
              <input type="text" v-model="search" placeholder="Pesquisar..." />
            </div>
          </b-col>
          <b-col sm="6">
            <div class="text-right">
              <Button _key="transferToSelected" type="info" classIcon="fa-solid fa-arrows-turn-right"
                title="Trans...todos" :disabled="productRent.length == 0" size="small"
                :clicked="transferAllToSelected" />
            </div>
          </b-col>
        </b-row>
      </div>
      <Loading type="line" :center="false" v-show="isLoading('movimentProductRent')" />
      <ScrollBar :minHeight="300" :maxHeight="600">
        <table class="table table-responsive-xs">
          <thead>
            <tr>
              <th>
                <span class="title-header">Produto</span>
              </th>
              <th class="text-center">
                <span class="title-header">
                  <span v-if="type == 'output'">Locação</span>
                  <span v-if="type == 'return'">Locado</span>
                  <span v-if="type == 'renewed'">Locado</span></span>
              </th>
              <th class="text-center">
                <span class="title-header">
                  <span v-if="type == 'output'">Saída</span>
                  <span v-if="type == 'return'">Retorno</span>
                  <span v-if="type == 'renewed'">Renovado</span>
                </span>
              </th>
              <th class="text-center">
                <span class="title-header">Status</span>
              </th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody v-for="item in productRentFiltered">
            <ItemProductRent :item="item" :type="type" />
          </tbody>
          <tbody v-show="productRentFiltered.length == 0">
            <tr>
              <td colspan="12">
                <span>Nenhum produto encontrado!</span>
              </td>
            </tr>
          </tbody>
        </table>
      </ScrollBar>
    </Molded>
    <Modal title="Patrimônio(s)" :width="600" :height="750" v-show="showModal('showPatrimony')">
      <ScrollBar :minHeight="200" :maxHeight="300">
        <div v-for="item in patrimony" :key="item.barCode">
          <span>{{ item.barCode }}</span>
        </div>
      </ScrollBar>
    </Modal>
  </div>
</template>
<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import ItemProductRent from "./ItemProductRent.vue";

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ProductRent",
  components: { Molded, Button, ScrollBar, ItemProductRent, Loading, Modal },
  props: {
    title: String,
  },
  data() {
    return {
      loading: true,
      urlOutput: "/api/v1/moviment/generate-output/transfer-to-selected",
      urlReturn: "/api/v1/moviment/generate-return/transfer-to-selected",
      urlRenewed: "/api/v1/moviment/generate-renewed/transfer-to-selected",
      patrimony: [],
      search: "",
      productRentFiltered: []
    };
  },
  mounted() {
    this.cleanProductRent();
  },
  computed: {
    ...mapState("generateMoviment", ["type", "productRent"]),
    ...mapGetters("generateMoviment", ["verifyIfExistSelected"]),
    ...mapGetters("generic", ["event", "showModal", "isLoading"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generateMoviment", [
      "addProductSelected",
      "cleanProductRent",
    ]),
    ...mapMutations("generic", ["removeLoading", "openModal"]),
    transferAllToSelected() {
      this.transferToSelected(this.productRent);
    },
    transferToSelected(data) {
      let url = "";
      if (this.type == "output") url = this.urlOutput;
      if (this.type == "return") url = this.urlReturn;
      if (this.type == "renewed") url = this.urlRenewed;

      let params = { url: url, obj: data, notNotifyToast: true };
      this.postApi(params).then((response) => {
        if (response.success) {
          response.content.forEach((item) => {
            if (!this.verifyIfExistSelected(item)) {
              item.type = this.type;

              this.addProductSelected(item);
            }
          });
        }
        this.removeLoading(["transferToSelected"]);
      });
    },
    applyFilter() {
      const searchLower = this.search.toLowerCase();
      this.productRentFiltered = this.productRent.filter(item =>
        item.productFullName.toLowerCase().includes(searchLower)
      );
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "transferToSelected") {
          this.transferToSelected([event.data]);
          this.removeLoading([event.data.productId + "a"]);
        }
        if (event.name == "showPatrimony") {
          this.openModal("showPatrimony");
          this.patrimony = event.data;
        }
      },
      deep: true,
    },
    search: {
      handler() {
        this.applyFilter();
      },
      deep: true,
    },
    productRent: {
      handler() {
        this.productRentFiltered = this.productRent;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.td-quantity {
  font-size: 16px;
}

.div-action {
  margin-bottom: 13px;
}

.div-title {
  margin-bottom: 10px;
}

.title {
  font-size: 15px;
}

.status-success {
  color: darkgreen;
  font-size: 18px;
}

.status-warning {
  color: #f6bb2a;
  font-size: 18px;
}

.status-danger {
  color: red;
  font-size: 18px;
}

.div-bar-code {
  margin-top: 27px;
}

.input-container {
  display: flex;
  justify-content: center;
  margin: 0px;
}

input {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #E5E4E8;
  border-radius: 20px;
  outline: none;
  font-size: 13px;
  width: 300px;
}
</style>