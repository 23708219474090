<template>
    <div>
        <div v-if="obj.products.length > 0" class="molded text-center">
            <div class="msg-success">Existem produtos pré-adicionados para serem incluídos na manutenção!
            </div>
            <div class="icon-success">
                <i class="fa-light fa-wrench"></i>
            </div>
            <Button _key="btnOpenProducts" type="primary" classIcon="fa-solid fa-list-timeline" title="Manutenção"
                size="medium" :clicked="openProducts" />
        </div>

        <Modal title="Gerar OS (Manutenção)" :width="900" v-show="showModal('maintenance')">
            <div v-if="loading">
                <span>Aguarde, estamos gerando, isso pode levar alguns segundos...</span>
                <Loading type="line" :center="false" />
            </div>
            <div v-else>
                <div v-if="success && !loading">
                    <div class="div-success text-center">
                        <i class="fa-light fa-circle-check icon-success"></i>
                        <div class="msg-success"> Ordem de Serviço Nº{{ os.number }}
                        </div>
                        <br>
                        <div>
                            <Button _key="btnNavegate" title="Ir para ordem de serviço" classIcon="fa-solid fa-link"
                                size="medium" :clicked="navegateTo" />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <b-row>
                        <b-col sm="6">
                            <InputText title="Identificação" field="identification" :required="true" :maxLength="100"
                                v-model="obj.identification" />
                        </b-col>
                        <b-col sm="3">
                            <DateTime title="Inicio" field="startDateHour" format="DD/MM/YYYY" type="date" placeholder
                                :markFormDirty="false" :required="true" v-model="obj.dateStart" />
                        </b-col>
                        <b-col sm="3">
                            <DateTime title="Fim (Previsão)" field="endDateHour" format="DD/MM/YYYY" type="date"
                                placeholder :markFormDirty="false" :required="true" v-model="obj.dateEnd" />
                        </b-col>
                    </b-row>
                    <ScrollBar v-if="obj.products.length > 0" :minHeight="400" :maxHeight="400">
                        <div v-for="product in obj.products">
                            <div class="molded-item">
                                <b-row>
                                    <b-col sm="8">
                                        <span class="title"> {{ product.productFullName }}</span>
                                        <InputTextEdit v-model="product.description" />
                                    </b-col>
                                    <b-col sm="2">
                                        <IncrementDecrement v-if="!product.patrimonyId" :min="1"
                                            :max="product.quantityMax" v-model="product.quantity" />
                                        <span v-else>
                                            <div class="text-center">
                                                <span class="title"> 1</span>
                                            </div>
                                        </span>
                                    </b-col>
                                    <b-col sm="1">
                                        <div class="icon-remove" @click="removeItem(product.productId)">
                                            <i class="fas fa-times"></i>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </ScrollBar>
                    <div v-else>Nenhum produto adicionado!</div>
                    <div class="text-center">
                        <Button _key="btnOpenProducts" :disabled="obj.products.length == 0" type="primary"
                            classIcon="fa-solid fa-circle-check" title="Gerar" size="medium" :clicked="generateOs" />
                    </div>
                </div>
            </div>

        </Modal>
    </div>
</template>

<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import IncrementDecrement from "@nixweb/nixloc-ui/src/component/forms/IncrementDecrement.vue";
import InputTextEdit from "@nixweb/nixloc-ui/src/component/forms/InputTextEdit.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
    name: "AddOrRemoveMaintenance",
    props: ["rentId"],
    components: {
        Button, Modal, ScrollBar, DateTime, InputText, InputNumber, IncrementDecrement, InputTextEdit, Molded, Loading
    },
    data() {
        return {
            urlCreate: "/api/v1/maintenance/order-service/create-from-moviment",
            success: false,
            loading: false,
            os: {},
            obj: {
                identification: "",
                dateStart: "",
                dateEnd: "",
                products: [],
            }
        }
    },
    computed: {
        ...mapGetters("generic", ["showModal"]),
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
        ...mapActions("generic", ["postApi"]),
        generateOs() {
            this.loading = true;
            var obj = { ...this.obj, ...{ rentId: this.rentId } }
            let params = { url: this.urlCreate, obj: obj };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.success = true;
                    this.os = response.content;
                    let self = this;
                    setTimeout(function () {
                        self.loading = false;
                    }, 3000);
                } else {
                    this.loading = false;
                }

                this.removeLoading(["btnOpenProducts"]);
            });
        },
        addOrRemove(data) {
            if (data.patrimony.length > 0) {
                data.patrimony.forEach(item => {
                    let obj = this.createPatrimony(item, data.productFullName);
                    this.addOrRemoveProduct(obj);
                });
            } else {
                let obj = this.createProduct(data);
                this.addOrRemoveProduct(obj);
            }
        },
        createPatrimony(item, productFullName) {
            var obj = {
                patrimonyId: item.id,
                productId: item.productId,
                productFullName: productFullName,
                barCode: item.barCode,
                description: "",
                quantity: 1
            }
            return obj;
        },
        createProduct(item) {
            var obj = {
                patrimonyId: null,
                productId: item.productId,
                productFullName: item.productFullName,
                barCode: null,
                description: "",
                quantity: item.totalSelected,
                quantityMax: item.totalSelected
            }
            return obj;
        },
        addOrRemoveProduct(newProduct) {
            const index = this.obj.products.findIndex(product => product.productId === newProduct.productId);

            if (index !== -1) {
                this.obj.products.splice(index, 1);
            } else {
                this.obj.products.push(newProduct);
            }
        },
        removeItem(productId) {
            this.obj.products = this.obj.products.filter(item => item.productId !== productId);
        },
        openProducts() {
            this.openModal("maintenance");
            this.removeLoading(["btnOpenProducts"]);
        },
        navegateTo() {
            this.hideModal();
            this.removeLoading(["btnNavegate"]);
            this.$router.push({
                name: "orderServiceUpdate",
                params: { id: this.os.id },
            });
        },

    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "addOrRemoveMaintenance") {
                    this.addOrRemove(event.data);
                }
            },
            deep: true,
        },
    }
}

</script>

<style scoped>
.molded {
    border: 1px solid #e8eaed;
    background-color: transparent;
    border-radius: 8px !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    height: 200px;
    width: 550px;
    margin: auto;
}

.molded-item {
    border: 1px solid #e8eaed;
    background-color: transparent;
    border-radius: 8px !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    height: 80px;
}

.icon-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 100px;
    cursor: pointer;
    color: #F0134D;
    cursor: pointer;
    font-size: 17px;
}

.msg-success {
    font-size: 16px;
}

.icon-success {
    font-size: 25px;
    margin-bottom: 10px;
    color: #38a169;
}


.div-success {
    padding-top: 30px;
    height: 200px;
}

.title-navegate {
    color: rgb(2, 2, 63);
    cursor: pointer;
    font-size: 16px;
}

.title-navegate:hover {
    text-decoration: underline;
}
</style>